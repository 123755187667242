@import "../../../../styles/material-colors.scss";


.row-train-info-header{
    border-bottom: 2px #e2e2e2 solid;
}

.history-item{
    .stripe{
        color: white;
        background: repeating-linear-gradient(
          -45deg,
          $clr-red-700,
          $clr-red-700 5px,
          $clr-red-500 5px,
          $clr-red-500 10px
        );
    }

    .badge {
        font-weight: normal;
        padding: 5px 10px;
        min-width: 43px;
    }

    .badge-light {
        border: 1px #ccc solid;
        padding: 4px 9px;
    }

    .badge-transparent{
        background: #f2f2f2;
    }

    .row-train-info {
        border-bottom: 1px #e2e2e2 solid;
        position: relative;

        &:not(.row-selected):hover {
            background: #f8f8f8;
        }

        .expand{
            position: absolute;
            right: 0;
            top: 0;  
            display: none;      
        }

        &:hover .expand{ 
            display: block;
            cursor: pointer;
        }

        .actions{
            position: absolute;
            left: 0;
            top: 0;     
            display: none;       
        }
    
        &:hover .actions{
            display: block !important; 
        }
    }

    .row-selected {
        border: 1px #e2e2e2 solid;
    }

    .data-source-type {
        width: 60px;
    }

    .train-type {
        width: 70px;
    }

    .wagon {
        margin-right: 1px !important;
        margin-left: 1px !important;
    }

    .row-is-not-valid{
        background: #fce9e9;
    }
 
    .col-comment{
        position: relative;
    } 

    .state-copied{
        display: none;
    }

    .btn-state-copied{
        .state-copy{
            display: none;
        }

        .state-copied{
            display: inline-block;
        }
    }
}