@import "material-colors.scss";



.table-in-table{
    margin-bottom: 0;
    font-size: smaller; 
}

.min-width{
    width:25px;
}
 
.table-hoverable-actions{
    .actions-block-container{
        position: relative;
    }

    .actions-block{
        position: absolute;
        top: 0; 
        right: 0;
        bottom: 0;  

        padding: 0 10px;
        display: none;
        justify-content: center; 
        align-items: center;    

        z-index: 100;
    }

     
    tr:hover{
        .actions-block{
            display: flex;
        }
    }
}

.deleted{
    background-color: #f2f2f2;
    color: #999
}

.invisible-link{
    color: inherit; 

    &:hover{
        color: $clr-light-blue;   
    } 
} 