.filters{
    .css-bg1rzq-control{
        border-color:rgb(206, 212, 218) !important;
    } 

    .date-picker{
        width: 150px !important;
    }

    .react-datepicker-wrapper{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        position: relative;
        flex: 1 1 auto;
        width: 1%;
        margin-bottom: 0;

        & input{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .data-sources-list{
        min-width: 300px;
    }

    .chip{
        border: 1px rgb(206, 212, 218) solid; 
        padding: 1px 15px;
        border-radius: 20px;
        color: #495057;
        font-size: 12px;   
        min-height: 40px;     
       
        &__title{
            color: rgb(164, 166, 167);
        }
    }  
}