.logs{
    .table-logs-data{
        font-size: 14px;
    }
    
    .log-exception{
        font-size: 12px;
    }
    
    .message-td{
        word-break: break-all;
        overflow:hidden;  
    }
}
