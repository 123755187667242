
@import "./material-colors.scss";

 
@media screen and (max-width: 600px) { 
    .table-mobile {
        border: none !important;

        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        tr {
            border-bottom: 1px solid #ddd;
            display: block;
            margin-bottom: 2em;

            td {
                border-bottom: 0;
                display: block;
                font-size: .8em;
                text-align: left;

                &::before { 
                    content: attr(data-label);
                    float: left; 
                    color: $clr-grey-500;
                    min-width: 30%;
                }

                &:last-child {
                    border-bottom: 0;
                }     
            }
        }
    } 
}
