@import "./../../styles/material-colors.scss";


.loading-app{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: $clr-blue-900;
    text-align: center;
    line-height: 100vh;
    font-size: 24px;
    color: #fff;

    .logo{
        vertical-align: middle;
        margin-top: -5px;
        margin-right: 10px;
    }
}