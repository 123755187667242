@import "material-colors.scss";

html, body{
    font-family: 'Roboto', sans-serif;
}

h5,
h6 {
  font-weight: 400;
}

a {
  color: $clr-light-blue;

  &:hover,
  &:focus {
    color: $clr-light-blue-500;
  }
}

.header {
  background: $clr-indigo;

  .navbar-brand img {
    height: 32px;
  }

  .nav-link {
    padding-top: 17px;
    padding-bottom: 17px;
    color: $clr-indigo-100 !important;

    

    &:hover {
      background: $clr-indigo-600;
      color: $clr-white !important;
    }

    &.active {
      background: $clr-indigo-700;
      color: $clr-white !important;
    }

    &[aria-expanded="true"] {
      background: $clr-indigo-400;
      color: $clr-white !important;
    }
  }

  .dropdown-menu {
    border: none;
    background: $clr-indigo;
    margin-top: -1px;
    border-radius: 0 0 5px 5px !important;

    .nav-link:last-of-type {
      border-radius: 0 0 5px 5px !important;
    }

    .dropdown-item:active,
    .dropdown-item {
      background-color: $clr-indigo !important;
      color: $clr-indigo-100;
    }

    .dropdown-item:hover {
      background: $clr-indigo-600 !important;
      color: $clr-white !important;
    }

    .dropdown-divider{
        border-top-color: $clr-indigo-300;
    }
  }  
}

.w-min {
  width: 1px;
}

.alert-transparent{
    border: 1px #ccc solid;
    background: #fff;
}

// .header {
//     background: $clr-indigo;

//     .navbar-brand img {
//         height:32px;
//     }

//     a {
//         color: $clr-white
//     }

//     #mainNavbar {
//         font-size: 0.9rem;

//         .nav-link {
//             padding-top: 15px;
//             padding-bottom: 15px;
//             color: $clr-grey-100;

//             &:hover {
//                 background: $clr-indigo-600;
//                 color: $clr-white;
//             }

//             &.active {
//                 background: $clr-indigo-700;
//                 color: $clr-white;
//             }

//             &[aria-expanded="true"] {
//                 background: $clr-indigo-400;
//             }
//         }

//         i {
//             margin: 0 4px;
//         }

//         .dropdown-menu {
//             border: none;
//             background: $clr-indigo;
//             margin-top: -1px;
//             padding: 0;
//             border-radius: 0 0 5px 5px !important;
//             font-size: 0.9rem;

//             .nav-link {
//                 padding: 12px 15px !important;
//             }

//             .nav-link:last-of-type {
//                 border-radius: 0 0 5px 5px !important;
//             }
//         }
//     }
// }

// .breadcrumb {
//     background-color: #fff;
//     padding-top: 15px;
//     padding-bottom: 5px;
//     font-size:14px;
// }

.thead-grey{
    background-color: $clr-grey-500 !important;
      
    th{
        font-weight: normal !important;
        color:#fff; 
        border-bottom:none !important;
    }
}

.w-10{ width: 10%!important;}
.w-20{ width: 20%!important; max-width: 200px;}
.w-30{ width: 30%!important;}
.w-33{ width: 33.3333%!important;}
.w-40{ width: 40%!important;}
.w-60{ width: 60%!important;}
.w-70{ width: 70%!important;}
.w-80{ width: 80%!important;}
.w-90{ width: 90%!important;}

.input-group-image img{
    height: 38px;
    border: 1px #ced4da solid;
    border-radius: 0.25rem 0 0 0.25rem;
}

@media (min-width: 1200px) {
    .container {
        max-width: 98% !important;
        width: 98% !important;
    }
}
  

@media (min-width: 767px) {
  i {
    margin: 0 4px;
  }

  .navbar {
    padding: 0 !important;
  }
}
